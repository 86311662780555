@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
.card-main {
  background: #ffffff;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.card-main.round {
  border-radius: 10px;
}

.card-main .card-heading {
  padding: 15px 20px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 500;
}

.card-main .card-heading span {
  color: #023054;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(16, 16, 16, 0.1);
}
/*# sourceMappingURL=Card.css.map */