@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
.Radio[type='radio']:checked {
  position: absolute;
  /* left: -9999px; */
  display: none;
}

.Radio[type='radio']:checked + label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}

.Radio[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #2eb3bd;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:checked + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #36c4ce;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.Radio[type='radio']:not(:checked) {
  position: absolute;
  /* left: -9999px; */
  display: none;
}

.Radio[type='radio']:not(:checked) + label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}

.Radio[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #74d6dd;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #36c4ce;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.Radio[type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}

.Radio[type='radio']:disabled + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #e8e8e8;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:disabled + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5fd0d8;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
/*# sourceMappingURL=Radio.css.map */
