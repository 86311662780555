@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap);
.lds-dual-ring {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=Spinner.css.map */
.select-control {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 32px;
  cursor: pointer;
}

.select-control .left-icon {
  padding-right: 10px;
}

.select-control .select-icon-up.closed,
.select-control .select-icon-down.closed {
  display: none !important;
}

.select-control .select-icon-up,
.select-control .select-icon-down {
  display: block;
}

.select-control .select-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: left;
  border: 1px solid #cccccc;
  outline: none;
  background-color: #ffffff;
  border-radius: 2px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: 0.3s ease;
  overflow: hidden;
}

.select-control .select-button select {
  flex: 1 1;
  height: 100%;
  outline: none;
  width: 100%;
  border: none;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.select-control .select-button select:focus {
  outline: none;
}

.select-control .select-button input.disabled {
  cursor: not-allowed !important;
  background-color: #ffffff;
}

.select-control .select-button i {
  margin-bottom: 2px;
  display: block;
}

.select-control .select-button:focus,
.select-control .select-button:focus-within {
  border-color: #36c4ce;
}

.select-control .select-button:hover {
  border-color: #36c4ce;
}

.select-control .select-button.secondary:hover {
  border-color: #023054;
}

.select-control .select-button.secondary:focus-within {
  border-color: #023054;
}

.select-control .select-button.disabled:hover,
.select-control .select-button.disabled:focus {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.select-control > * {
  font-family: 'Roboto', sans-serif;
}

.select-control .select-error-text {
  margin-top: 5px;
  color: #da4e6a;
}

.select-control ul {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 600px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: gray;
  background-color: #ffffff;
  max-height: 350px;
  overflow-y: auto;
  transition: 0.3s ease;
  visibility: visible;
  opacity: 1;
  /* width */
  /* Handle */
}

.select-control ul::-webkit-scrollbar {
  width: 3px;
}

.select-control ul::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.select-control ul li {
  display: block;
  padding: 12px 10px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.select-control ul li:hover {
  cursor: pointer;
  background-color: #023054;
  color: #ffffff;
}

.select-control ul.closed {
  visibility: hidden;
  opacity: 0;
  height: 0;
  border: none;
}

.select-control.error .select-button {
  border-color: #da4e6a;
}

.select-control.error .select-button input {
  color: #da4e6a;
}

.select-control.small {
  height: 25px;
}

.select-control.small .select-button {
  font-size: 12px;
}

.select-control.small ul {
  max-height: 300px;
}

.select-control.small ul li {
  padding: 5px 10px;
  font-size: 12px;
}

.select-control.large {
  height: 55px;
}

.select-control.large .select-button {
  font-size: 18px;
}

.select-control.large ul {
  max-height: 600px;
}

.select-control.large ul li {
  padding: 10px;
  font-size: 17px;
}
/*# sourceMappingURL=Select.css.map */

.input-control {
  position: relative;
}

.input-control > * {
  font-family: "Roboto", sans-serif;
}

.input-control .input-container-cover {
  display: flex;
}

.input-control .input-error-text {
  margin-top: 5px;
  color: #da4e6a;
}

.input-control .dropdown-control {
  height: 50px;
  width: 120px;
  margin-right: 10px;
}

.input-control .dropdown-control input {
  text-align: center;
}

.input-control .input-field {
  width: 100%;
  height: 40px;
  border: 1px solid #e6e6e6;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  transition: .5s ease;
}

.input-control .input-field i:first-child {
  display: block;
  padding-left: 10px;
}

.input-control .input-field i:last-child {
  display: block;
  padding-right: 10px;
}

.input-control .input-field:hover {
  border-color: #36C4CE;
}

.input-control .input-field:focus-within {
  border-color: #36C4CE;
  box-shadow: 0 5px 10px rgba(54, 196, 206, 0.3);
}

.input-control .input-field.disabled:hover,
.input-control .input-field.disabled:focus {
  border-color: #f2f2f2 !important;
  box-shadow: none !important;
}

.input-control .input-field.secondary:hover {
  border-color: #023054;
}

.input-control .input-field.secondary:focus-within {
  border-color: #023054;
  box-shadow: 0 5px 10px rgba(2, 48, 84, 0.3);
}

.input-control input {
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  outline: none;
  background-color: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.input-control input:disabled {
  cursor: not-allowed;
}

.input-control input i {
  float: right;
  text-align: right !important;
}

.input-control input::-webkit-input-placeholder {
  color: #cccccc;
}

.input-control.error .input-field {
  border-color: #da4e6a;
}

.input-control.error .input-field input {
  color: #da4e6a;
}

.input-control.error .input-field:focus-within {
  border-color: #da4e6a;
  box-shadow: 0 5px 10px rgba(218, 78, 106, 0.3);
}

.input-control.small .input-field {
  height: 25px;
}

.input-control.small .input-field input {
  font-size: 12px;
}

.input-control.large .input-field {
  height: 55px;
}

.input-control.large .input-field input {
  font-size: 18px;
}
/*# sourceMappingURL=Input.css.map */
.Radio[type='radio']:checked {
  position: absolute;
  /* left: -9999px; */
  display: none;
}

.Radio[type='radio']:checked + label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}

.Radio[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #2eb3bd;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:checked + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #36c4ce;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
  opacity: 1;
  transform: scale(1);
}

.Radio[type='radio']:not(:checked) {
  position: absolute;
  /* left: -9999px; */
  display: none;
}

.Radio[type='radio']:not(:checked) + label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #999999;
}

.Radio[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #74d6dd;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #36c4ce;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
  opacity: 0;
  transform: scale(0);
}

.Radio[type='radio']:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}

.Radio[type='radio']:disabled + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #e8e8e8;
  border-radius: 100%;
  background: transparent;
}

.Radio[type='radio']:disabled + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5fd0d8;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
/*# sourceMappingURL=Radio.css.map */

.Checkbox[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.Checkbox[type="checkbox"]:checked + label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #909090;
}

.Checkbox[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: none;
  background: #88dce2;
}

.Checkbox[type="checkbox"]:checked + label:after {
  content: '';
  width: 10px;
  height: 5px;
  background: transparent;
  position: absolute;
  border: 2px solid transparent;
  border: solid #ffffff;
  border-width: 0 0 2px 2px;
  top: 6px;
  left: 5px;
  transition: all 0.2s ease;
  opacity: 1;
  transform: scale(1);
  transform: rotate(-45deg);
}

.Checkbox[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.Checkbox[type="checkbox"]:not(:checked) + label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #909090;
}

.Checkbox[type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: none;
  background: #88dce2;
}

.Checkbox[type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 5px;
  background: transparent;
  position: absolute;
  border: 2px solid transparent;
  border: solid #ffffff;
  border-width: 0 0 2px 2px;
  top: 6px;
  left: 5px;
  transition: all 0.2s ease;
  opacity: 0;
  transform: scale(0);
  transform: rotate(0deg);
}

.Checkbox[type="checkbox"]:disabled + label {
  cursor: not-allowed;
  opacity: .7;
}

.Checkbox[type="checkbox"]:disabled + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: none;
  background: #e6e6e6;
}

.Checkbox[type="checkbox"]:disabled + label:after {
  content: '';
  width: 10px;
  height: 5px;
  background: transparent;
  position: absolute;
  border: 2px solid transparent;
  border: solid white;
  border-width: 0 0 2px 2px;
  top: 6px;
  left: 5px;
  transition: all 0.2s ease;
}
/*# sourceMappingURL=Checkbox.css.map */
.btn-main {
  background: #36C4CE;
}

.btn {
  font-family: "Roboto", sans-serif;
  background: transparent;
  border-radius: 4px;
  padding: 8px 20px;
  color: white;
  border: none;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-sizing: border-box;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn:focus,
.btn:active,
.btn-sml:focus,
.btn-sml:active,
.btn {
  outline: none;
  position: relative;
  text-decoration: none;
  box-shadow: -3px 21px 42px -38px #5680f9;
}

.btn:focus.primary,
.btn:active.primary,
.btn-sml:focus.primary,
.btn-sml:active.primary,
.btn.primary {
  background: #36C4CE;
  border: 2px solid #36C4CE;
}

.btn:focus.primary:hover,
.btn:active.primary:hover,
.btn-sml:focus.primary:hover,
.btn-sml:active.primary:hover,
.btn.primary:hover {
  background: #2eb3bd;
  border-color: #2eb3bd;
}

.btn:focus.primary.outlined,
.btn:active.primary.outlined,
.btn-sml:focus.primary.outlined,
.btn-sml:active.primary.outlined,
.btn.primary.outlined {
  border: 2px solid #36C4CE;
  color: #36C4CE;
}

.btn:focus.primary.outlined:hover,
.btn:active.primary.outlined:hover,
.btn-sml:focus.primary.outlined:hover,
.btn-sml:active.primary.outlined:hover,
.btn.primary.outlined:hover {
  background: #36C4CE;
}

.btn:focus.secondary,
.btn:active.secondary,
.btn-sml:focus.secondary,
.btn-sml:active.secondary,
.btn.secondary {
  background: #023054;
  border: 2px solid #023054;
}

.btn:focus.secondary:hover,
.btn:active.secondary:hover,
.btn-sml:focus.secondary:hover,
.btn-sml:active.secondary:hover,
.btn.secondary:hover {
  background: #01223b;
  border-color: #01223b;
}

.btn:focus.secondary.outlined,
.btn:active.secondary.outlined,
.btn-sml:focus.secondary.outlined,
.btn-sml:active.secondary.outlined,
.btn.secondary.outlined {
  border: 2px solid #023054;
  color: #023054;
}

.btn:focus.secondary.outlined:hover,
.btn:active.secondary.outlined:hover,
.btn-sml:focus.secondary.outlined:hover,
.btn-sml:active.secondary.outlined:hover,
.btn.secondary.outlined:hover {
  background: #023054;
}

.btn:focus.danger,
.btn:active.danger,
.btn-sml:focus.danger,
.btn-sml:active.danger,
.btn.danger {
  background: #da4e6a;
  border: 2px solid #da4e6a;
}

.btn:focus.danger:hover,
.btn:active.danger:hover,
.btn-sml:focus.danger:hover,
.btn-sml:active.danger:hover,
.btn.danger:hover {
  background: #d63958;
  border-color: #d63958;
}

.btn:focus.danger.outlined,
.btn:active.danger.outlined,
.btn-sml:focus.danger.outlined,
.btn-sml:active.danger.outlined,
.btn.danger.outlined {
  border: 2px solid #da4e6a;
  color: #da4e6a;
}

.btn:focus.danger.outlined:hover,
.btn:active.danger.outlined:hover,
.btn-sml:focus.danger.outlined:hover,
.btn-sml:active.danger.outlined:hover,
.btn.danger.outlined:hover {
  background: #da4e6a;
}

.btn:focus.success,
.btn:active.success,
.btn-sml:focus.success,
.btn-sml:active.success,
.btn.success {
  background: #49B26D;
  border: 2px solid #49B26D;
}

.btn:focus.success:hover,
.btn:active.success:hover,
.btn-sml:focus.success:hover,
.btn-sml:active.success:hover,
.btn.success:hover {
  background: #42a062;
  border-color: #42a062;
}

.btn:focus.success.outlined,
.btn:active.success.outlined,
.btn-sml:focus.success.outlined,
.btn-sml:active.success.outlined,
.btn.success.outlined {
  border: 2px solid #49B26D;
  color: #49B26D;
}

.btn:focus.success.outlined:hover,
.btn:active.success.outlined:hover,
.btn-sml:focus.success.outlined:hover,
.btn-sml:active.success.outlined:hover,
.btn.success.outlined:hover {
  background: #49B26D;
}

.btn:focus.default,
.btn:active.default,
.btn-sml:focus.default,
.btn-sml:active.default,
.btn.default {
  background: #ffffff;
  color: #101010;
  box-shadow: 0 2px 5px rgba(16, 16, 16, 0.1);
}

.btn:focus.default:hover,
.btn:active.default:hover,
.btn-sml:focus.default:hover,
.btn-sml:active.default:hover,
.btn.default:hover {
  background: #e6e6e6;
}

.btn:focus.outlined,
.btn:active.outlined,
.btn-sml:focus.outlined,
.btn-sml:active.outlined,
.btn.outlined {
  background: transparent;
  transition: .3s ease;
}

.btn:focus.outlined:hover,
.btn:active.outlined:hover,
.btn-sml:focus.outlined:hover,
.btn-sml:active.outlined:hover,
.btn.outlined:hover {
  color: white;
}

.btn:focus.loading,
.btn:active.loading,
.btn-sml:focus.loading,
.btn-sml:active.loading,
.btn.loading {
  opacity: 0.7;
}

.btn:focus.loading > span,
.btn:active.loading > span,
.btn-sml:focus.loading > span,
.btn-sml:active.loading > span,
.btn.loading > span {
  margin-right: 6px;
}

.btn:focus:hover,
.btn:active:hover,
.btn-sml:focus:hover,
.btn-sml:active:hover,
.btn:hover {
  cursor: pointer;
}

.btn:focus:disabled,
.btn:focus .disabled,
.btn:active:disabled,
.btn:active .disabled,
.btn-sml:focus:disabled,
.btn-sml:focus .disabled,
.btn-sml:active:disabled,
.btn-sml:active .disabled,
.btn:disabled,
.btn .disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn:focus.block,
.btn:active.block,
.btn-sml:focus.block,
.btn-sml:active.block,
.btn.block {
  width: 100%;
  display: block;
}

.btn:focus .icon-left,
.btn:active .icon-left,
.btn-sml:focus .icon-left,
.btn-sml:active .icon-left,
.btn .icon-left {
  padding-right: 10px;
}

.btn:focus .content,
.btn:active .content,
.btn-sml:focus .content,
.btn-sml:active .content,
.btn .content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=Button.css.map */
.card-main {
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.card-main.round {
  border-radius: 10px;
}

.card-main .card-heading {
  padding: 15px 20px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 500;
}

.card-main .card-heading span {
  color: #023054;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(16, 16, 16, 0.1);
}
/*# sourceMappingURL=Card.css.map */
textarea {
  height: auto;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  min-width: 100%;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  display: block;
  margin: 0;
  outline: none;
  font-size: 14px;
  transition: 0.3s ease;
  background-color: #ffffff;
}

textarea:not(.textarea-error):hover {
  border-color: #36C4CE;
}

textarea:not(.textarea-error):focus {
  border-color: #36C4CE;
  box-shadow: 0 5px 10px rgba(54, 196, 206, 0.3);
}

textarea.secondary:hover {
  border-color: #023054;
}

textarea.secondary:focus {
  border-color: #023054;
  box-shadow: 0 5px 10px rgba(2, 48, 84, 0.3);
}

textarea:disabled:hover {
  border: 1px solid #e6e6e6;
}

.textarea-error,
.textarea-error:hover,
.textarea-error:focus {
  border: 1px solid #da4e6a;
  outline: none !important;
}

.error {
  color: #da4e6a;
  font-size: 14px;
}
/*# sourceMappingURL=TextAreaField.css.map */
.form-group {
  margin-bottom: 30px;
}

@media screen and (max-width: 480px) {
  .form-group {
    margin-bottom: 15px;
  }
}

.form-group label {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  color: #5d5d5d;
}

@media screen and (max-width: 480px) {
  .form-group label {
    font-size: 12px;
  }
}
/*# sourceMappingURL=formGroup.css.map */
.notification-main {
  width: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  color: white;
  font-family: "Montserrat", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
  margin-bottom: 20px;
  z-index: 100000;
}
.notification-main > * {
  width: unset !important;
}
.notification-main .close-button {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
  width: unset !important;
}
.notification-main .close-button > * {
  width: unset !important;
}
.notification-main .close-button i {
  width: unset !important;
}
.notification-main .close-button i > * {
  width: unset !important;
}
.notification-main .close-button svg {
  width: unset !important;
}
.notification-main .close-button svg > * {
  width: unset !important;
}
.notification-main .icon-contain {
  width: unset !important;
}
.notification-main .icon-contain > * {
  width: unset !important;
}
.notification-main .icon-contain svg {
  width: unset !important;
}
.notification-main .contents {
  padding: 5px 20px;
  font-size: 14px;
}
.notification-main .contents > * {
  width: unset !important;
}
.notification-main .contents .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.notification-main.success {
  background-color: #49B26D;
  color: #fff;
}

.notification-main.info {
  background-color: #49B26D;
  color: #fff;
}

.notification-main.warning {
  background-color: #ee7511;
}

.notification-main.error {
  background-color: #da4e6a;
}

.notification-main.default {
  color: black;
}

.notification-main.close {
  visibility: hidden;
  opacity: 0;
}

.notification-main.remove {
  display: none !important;
}

#notification-root {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10 !important;
  width: unset;
}
#notification-root > * {
  width: unset !important;
}

.notification-bubble {
  width: 500px;
  max-width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: 0.3s ease;
  animation: animateNotificationBubble 0.3s ease-in-out forwards;
}
@media screen and (max-width: 480px) {
  .notification-bubble {
    width: unset;
  }
}

@keyframes animateNotificationBubble {
  to {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/*# sourceMappingURL=Notification.css.map */

.file-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.file-upload-main .file-upload-items {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  height: 150px;
  width: 150px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-origin: left top;
  transform: scale(0);
  animation: animateFileEntry 0.3s ease-in-out forwards;
  overflow: hidden;
  position: relative;
}
.file-upload-main .file-upload-items .loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /**
  * $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
  */
}
.file-upload-main .file-upload-items .loading .progress-info {
  position: relative;
  width: 100%;
}
.file-upload-main .file-upload-items .loading .progress-info::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  content: attr(data-info);
}
.file-upload-main .file-upload-items .loading .progress-circle {
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  position: relative;
  width: 100px;
}
.file-upload-main .file-upload-items .loading .progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-progress) "%";
  display: flex;
  font-size: 12px;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  transition: transform 0.2s ease;
}
.file-upload-main .file-upload-items .loading .progress-circle:after {
  background-color: #0083ff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(90deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(93.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(97.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(100.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(104.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(108deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(111.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(115.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(118.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(122.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(126deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(144deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(147.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(151.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(154.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(158.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(162deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(165.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(169.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(172.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(176.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(180deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(183.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(187.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(190.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(194.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(198deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(201.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(205.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(208.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(212.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(216deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(219.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(223.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(226.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(230.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(234deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(237.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(241.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(244.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(248.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(252deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(255.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(259.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(262.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(266.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(-90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(-86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(-82.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(-79.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(-75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(-72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(-68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(-64.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(-61.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(-57.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(-54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(-50.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(-46.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(-43.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(-39.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(-36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(-32.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(-28.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(-25.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(-21.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(-18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(-14.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(-10.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(-7.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(-3.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(0deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(3.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(7.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(10.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(14.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(21.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(25.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(28.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(32.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(39.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(43.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(46.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(50.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(57.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(61.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(64.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(79.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(82.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading.close {
  z-index: -1;
}
.file-upload-main .file-upload-items .file-upload-controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.file-upload-main .file-upload-items .file-upload-controls button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  color: white;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s ease;
}
.file-upload-main .file-upload-items .file-upload-controls button:hover {
  background: rgba(0, 0, 0, 0.5);
}
.file-upload-main .file-upload-items img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  border: none;
}
.file-upload-main .file-upload-items:hover .file-upload-controls {
  z-index: 3;
  visibility: visible;
}
.file-upload-main.single .file-upload-items {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.file-upload-main .file-upload-items.error {
  border-color: #da4e6a;
}
.file-upload-main .file-upload-items.error .file-upload-controls .preview-button {
  display: none;
}
.file-upload-main .file-upload-button {
  height: 150px;
  width: 150px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}
.file-upload-main .file-upload-items.close {
  transform-origin: left top;
  transform: scale(1);
  animation: animateFileExit 0.3s ease-in-out forwards;
}

@keyframes animateFileEntry {
  to {
    transform: scale(1);
  }
}
@keyframes animateFileExit {
  to {
    transform: scale(0);
  }
}

/*# sourceMappingURL=FileUpload.css.map */

.dropdown-main {
  position: relative;
  z-index: 4;
}

.dropdown-main .dropdown-content {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-main ul {
  position: absolute;
  width: 100px;
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: calc(100% + 10px);
  right: 0;
  box-shadow: 0 5px 10px rgba(16, 16, 16, 0.1);
  max-height: 400px;
  overflow-y: auto;
  transform-origin: right top;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
  transition: .5s ease;
  /* width */
  /* Handle */
}

.dropdown-main ul::-webkit-scrollbar {
  width: 3px;
}

.dropdown-main ul::-webkit-scrollbar-thumb {
  background: #d8d6d6;
  border-radius: 10px;
}

.dropdown-main ul li {
  list-style: none;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2E2E2E;
  font-size: 14px;
  padding: 12px 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.dropdown-main.open ul {
  opacity: 1;
  visibility: visible;
}
/*# sourceMappingURL=Dropdown.css.map */
.modal-main {
  width: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  font-family: "Montserrat", sans-serif;
  transition: .3s ease;
  margin-bottom: 20px;
  color: #101010;
}

.modal-main > * {
  width: unset !important;
}

.modal-main .close-button {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
}

.modal-main .close-button > * {
  width: unset !important;
}

.modal-main .close-button i {
  width: unset !important;
}

.modal-main .close-button i > * {
  width: unset !important;
}

.modal-main .close-button svg {
  width: unset !important;
}

.modal-main .close-button svg > * {
  width: unset !important;
}

.modal-main .contents {
  padding: 5px 20px;
  font-size: 14px;
  width: 100%;
  color: #101010;
}

.modal-main .contents .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.modal-main.success {
  color: #6bc389;
}

.modal-main.info {
  color: #034c86;
}

.modal-main.warning,
.modal-main.confirm {
  color: #f19141;
}

.modal-main.error {
  color: #da4e6a;
}

.modal-main.close {
  visibility: hidden;
  opacity: 0;
}

.modal-main.close > * {
  width: unset !important;
}

.modal-main.remove {
  display: none !important;
}

#modal-root {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100001;
}

.modal-node {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease;
  z-index: 1;
}

.modal-bubble {
  min-width: 300px;
  max-width: 600px;
  box-sizing: border-box;
  background-color: white;
  transition: .3s ease;
  box-shadow: 0 2px 5px rgba(16, 16, 16, 0.1);
  animation: animateModalBubble .3s ease-in-out forwards;
}

.modal-bubble .icon-contain svg {
  width: unset !important;
}

.modal-bubble .icon-contain svg > * {
  width: unset !important;
}

.modal-bubble .icon-contain svg rect {
  width: 30px;
}

.modal-bubble .components {
  width: 100%;
  padding: 0 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}

.modal-bubble .components > * {
  width: unset !important;
}

.modal-bubble .components .closebutt {
  align-self: flex-end;
  margin-left: 10px;
}

.modal-bubble .components .closebutt > * {
  width: unset !important;
}

.modal-root-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(16, 16, 16, 0.7);
  z-index: 100001;
}

.modal-root-fixed .modal-bubble-fixed {
  width: 600px;
  max-width: 100%;
  background-color: white;
  transition: .5s ease;
  box-shadow: 0 2px 5px rgba(16, 16, 16, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  max-height: 80vh;
  overflow-y: auto;
  animation: modalBubble .3s ease-in-out forwards;
}

.modal-root-fixed .modal-bubble-fixed .close-button {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
}

.modal-root-fixed .modal-bubble-fixed .title {
  padding: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid rgba(16, 16, 16, 0.1);
}

.modal-root-fixed .modal-bubble-fixed .contents {
  padding: 20px;
}

.modal-root-fixed .modal-bubble-fixed .footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-root-fixed .modal-bubble-fixed .footer button {
  margin-right: 10px;
}

.modal-node.closed {
  opacity: 0;
}

.modal-node.closed .modal-bubble {
  animation: animateModalOut .3s ease-in-out forwards;
}

@keyframes animateModalBubble {
  from {
    opacity: 0;
    transform: rotateX(90deg);
    margin-top: 200px;
  }
  to {
    opacity: 1;
    margin-top: 20px;
    transform: rotateX(0);
  }
}

@keyframes animateModalOut {
  from {
    opacity: 1;
    margin-top: 20px;
    transform: rotateX(0);
  }
  to {
    opacity: 0;
    margin-top: -100px;
    transform: rotateX(0);
  }
}

@keyframes modalBubble {
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
/*# sourceMappingURL=Modal.css.map */
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
}

table {
  font-size: 12px;
}

a {
  color: #36c4ce;
  text-decoration: none;
}
.vertical-line {
  display: inline-block;
  border-left: 2px solid rgba(238, 238, 238, .5);
  border-radius: 3px;
  margin: 0 10px;
  height: 60px;
}
.basic-multi-select {
  width: 300px;
}
.flex-column .basic-multi-select {
  width: 100%;
  min-width: 400px;
}
.row {
  max-width: 500px;
}
.checkbox label {
  padding-top: 2px;
  padding-left: 10px;
}
.checkbox {
  display: flex;
  align-items: center;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ant-input {
  min-height: 38px;
}
.input-small-top {
  font-size: 10px;
  margin-top: -25px;
  margin-bottom: 20px;
}

.h-32px {
  height: 32px !important;
}

.rt-td {
  overflow: unset;
}

.ck-content {
  min-height: 300px;
}

.link {
  color: #36c4ce;
  cursor: pointer;
}

.DateRangePickerInput {
  background-color: #36c4ce;
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.DateRangePickerInput_arrow_svg {
  fill: #ffffff;
}

.DateInput_input {
  font-size: 13px;
  font-weight: 500;
  padding: 8px;
  line-height: 15px;
  background-color: #36c4ce;
  color: white;
  text-align: center;
  padding-top: 12px;
}

.settings_gear {
  cursor: pointer;
  transition: 1s ease;
  transform: rotateZ(0deg);
  transform-origin: center;
  display: block;
  color: #49b26d;
}
.settings_gear:hover {
  transform: rotateZ(180deg);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading-main {
  font-size: 18px;
  color: #36c4ce;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 10px;
}

.heading-content {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #666;
}

.btn-more {
  text-align: center;
  color: #b3b3b3;
  transition: 0.3s ease;
  margin-top: 20px;
}
.btn-more:hover {
  color: #36c4ce;
  cursor: pointer;
}

* {
  width: unset;
}

a,
a:hover {
  color: unset;
}

h1,
h3 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #666666;
}

.flex {
  display: flex;
}

.flew-wrap {
  flex-wrap: wrap;
}

.align-c {
  align-items: center;
}

.align-b {
  align-items: flex-end;
}

.align-a {
  align-items: flex-start;
}

.status-badge {
  color: #ffffff;
  background-color: #ee7511;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 10px;
  text-transform: capitalize;
}
.status-badge.approved,
.status-badge.true {
  background-color: #49b26d;
}
.status-badge.pending,
.status-badge.false {
  background-color: #ee7511;
}
.status-badge.failed,
.status-badge.rejected {
  background-color: #da4e6a;
}

.capitalize {
  text-transform: capitalize;
}

.flex-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.c-pointer {
  cursor: pointer;
}

.color-sec {
  color: #023054;
}

.color-pri {
  color: #36c4ce;
}

.max-width-1400 {
  max-width: 1400px;
  margin: 0 auto;
}

.max-width-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.content-container {
  padding-top: 50px;
}
.content-container .content-head {
  margin-bottom: 30px;
}

.content-card {
  background: white;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 30px;
}
.content-card .card-header,
.content-card .card-content {
  padding: 20px;
  box-sizing: border-box;
}
.content-card .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.content-card .card-header .header-type {
  padding: 10px 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  color: #022d4e;
  font-weight: 500;
}

.padding-20 {
  padding: 20px;
}

.padding-10 {
  padding: 10px;
}

.padding-t-10 {
  padding-top: 10px;
}

.padding-h-20 {
  padding: 0 20px;
}

.padding-l-20 {
  padding-left: 20px;
}

.padding-r-20 {
  padding-right: 20px;
}

.padding-t-20 {
  padding-top: 20px;
}

.padding-0 {
  padding: 0 !important;
}
.padding-0 > * {
  padding: 0 !important;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.text-container {
  width: 100%;
  max-width: 500px;
}
.text-container .counter {
  float: right;
}

.flex-1 {
  flex: 1 1;
}

.max-width-800 {
  max-width: 800px;
}

.max-width-700 {
  max-width: 700px;
}

.max-width-600 {
  max-width: 600px;
}

.max-width-500 {
  max-width: 500px;
}

.max-width-400 {
  max-width: 400px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-r-20 {
  margin-right: 20px;
}

.info {
  font-size: 12px;
  color: #999999;
}

.float-r {
  float: right;
}

.v-tab {
  width: 200px;
  margin-right: 20px;
  border-right: 1px solid #36c4ce;
  background-color: #fafafa;
}
.v-tab li {
  list-style: none;
  font-size: 12px;
  padding: 10px 20px;
  cursor: pointer;
}
.v-tab li.active {
  background-color: #36c4ce;
  color: #ffffff;
}

.float-r {
  float: right;
}

.content-heading {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.minmal {
  width: 750px;
}
.minmal .btn-main {
  float: right;
  margin-top: 20px;
  margin-right: 40px;
}

.grid-4 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
}

.grid-3 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.grid-2 {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .grid-2 {
    grid-template-columns: 1fr;
  }
}

.grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2em;
}

.clear {
  clear: both;
}

.hide {
  display: none !important;
}
.providerCardInfo {
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.providerCardInfo .mainFig {
  font-size: 25px;
  opacity: 0.7;
  margin-bottom: 10px;
}
.revenueCardInfo {
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.revenueCardInfo .inner-content {
}

.productCardInfo {
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productCardInfo .title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  opacity: 0.7;
}
.productCardInfo .mainFig {
  font-size: 25px;
}

.productCardInfo .inner-content,
.providerCardInfo .inner-content {
  text-align: center;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .cat-input {
  width: 100%;
  margin-left: 20px;
  max-width: 530px;
}
.category-container .service-item {
  flex: 1 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.nav-bar .search-input {
  border: none !important;
}
.nav-bar .search-input input::-webkit-input-placeholder {
  color: #e6e6e6;
}
.nav-bar .search-input input:-ms-input-placeholder {
  color: #e6e6e6;
}
.nav-bar .search-input input::placeholder {
  color: #e6e6e6;
}
.nav-bar .search-drop {
  position: fixed;
  top: 100px;
  max-width: 1400px;
  width: 80%;
  right: 100px;
  z-index: 100;
  display: none;
  box-sizing: border-box;
}
.nav-bar .search-drop.show {
  display: block;
}
.nav-bar .nav-item-drop {
  font-size: 12px;
  color: #999999;
}
.nav-bar .nav-item-drop.danger {
  color: #da4e6a;
}
.nav-bar .bar {
  height: 30px;
  border-right: 1px solid #e6e6e6;
  margin: 0 20px;
}
.nav-bar .user-profile {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 20px;
}
.nav-bar .user-profile .user-label {
  font-weight: 600;
  margin-right: 20px;
}
.nav-bar .user-profile .image-con {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #e6e6e6;
  cursor: pointer;
}
.nav-bar .user-profile .image-con img {
  width: 100%;
  height: 100%;
}

.react-table {
  border: none;
}
.react-table .rt-thead.-header {
  box-shadow: none;
}
.react-table .rt-thead .rt-td,
.react-table .rt-thead .rt-th,
.react-table .rt-tbody .rt-td {
  border-right: none !important;
}
.react-table .rt-tbody .rt-td {
  padding: 0 10px;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.react-table .rt-thead .rt-th {
  padding: 20px 10px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #36c4ce;
  text-align: left;
}
.react-table .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #f7f7f7;
  transition: 0.3s ease;
}
.react-table .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(16, 16, 16, 0.02);
}
.react-table .-pagination {
  box-shadow: none;
}

.account-tabs {
  position: relative;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}
.account-tabs li {
  list-style: none;
  padding: 20px;
  position: relative;
  cursor: pointer;
}
.account-tabs li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 2px;
  background-color: #36c4ce;
  transform: translateY(75%);
}

.goBack {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.card-control {
  padding: 20px;
}
.card-control .account-inner-tab {
  background-color: #fafafa;
  height: 100%;
  margin-left: -20px;
  border-right: 2px solid #36c4ce;
  margin-right: 20px;
  padding-bottom: 40px;
}
.card-control .account-inner-tab li {
  font-size: 14px;
  margin: 0;
  padding: 10px;
  color: #666666;
  font-weight: 500;
}
.card-control .account-inner-tab li small {
  font-size: 10px;
  display: block;
}
.card-control .account-inner-tab li.active {
  background-color: #36c4ce;
  color: #ffffff;
}
.card-control .account-inner-tab li.active > * {
  color: #ffffff;
}
.card-control .airtime-card {
  padding: 20px;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  position: relative;
}
.card-control .airtime-card .close {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
}
.card-control .select-control ul > * {
  margin: 0 !important;
}
.card-control .item {
  flex: 1 1;
}
.card-control .item .item-head {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
}
.card-control .item .image-con {
  width: 150px;
  height: 150px;
  background-color: #e6e6e6;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.card-control ul li {
  font-weight: 300;
  font-size: 13px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.card-control ul li span {
  margin-right: 10px;
}
.card-control .code-item .header {
  font-weight: 300;
  font-size: 13px;
  color: #36c4ce;
  margin-bottom: 10px;
  margin-top: 20px;
}
.card-control .code-item li {
  list-style: none;
  display: inline-block;
  padding: 5px 30px;
  background-color: #cccccc;
  margin-right: 10px;
  color: #ffffff;
}

.campTable {
  width: 100%;
}
.campTable td,
.campTable th {
  padding: 20px 20px;
}
.campTable th {
  width: 200px;
}
.campTable tr {
  border-bottom: 1px solid #f2f2f2;
}
.campTable tr:last-child {
  border-bottom: none;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .cat-input {
  width: 100%;
  margin-left: 20px;
  max-width: 530px;
}
.category-container .service-item {
  flex: 1 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.invoice-main li {
  list-style: none;
  display: block;
  flex: 1 1;
  color: black;
}
.invoice-main .heading-content li {
  font-weight: bold;
}
.invoice-main .divider {
  border-color: black;
}

.finance-control .productCardInfo {
  height: 200px;
}

.card-btn {
  margin-bottom: 20px;
}
.card-btn button {
  padding: 5px 20px;
  border: 2px solid white;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease;
  outline: none;
}
.card-btn button:hover {
  background-color: inherit;
  border-color: inherit;
}

.flex-2 {
  flex: 2 1;
}

.flex-3 {
  flex: 3 1;
}

.flex-4 {
  flex: 4 1;
}

.text-align-r {
  text-align: right;
}

.text-align-l {
  text-align: left;
}

.black-color {
  color: black;
  border-color: black;
}

.login-main {
  background-color: #f9feff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.login-main h3 {
  font-size: 16px;
  text-align: left !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}
.login-main form {
  width: 400px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.05);
}

.tab-heading {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  /* width */
}
.tab-heading::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.tab-heading li {
  list-style: none;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  font-weight: 500;
  border-top: 1.5px solid transparent;
  cursor: pointer;
  color: #b3b3b3;
}
.tab-heading li.active {
  color: #36c4ce;
  border-color: #36c4ce;
}

.tab-content .tab-item {
  display: none;
  padding: 20px;
}
.tab-content .tab-item.active {
  display: block;
}

.custom-flex {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5em;
  margin-top: 10px;
}

.create-comp {
  font-size: 12px;
  font-weight: 500;
  color: #36c4ce;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
}
.create-comp span {
  margin-left: 5px;
}
.create-comp div:last-child {
  margin-top: 2px;
  margin-left: 5px;
}

.category-item {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height: 200px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease;
  cursor: pointer;
  transform: scale(1);
}
.category-item:hover {
  transform: scale(1.1);
  border-color: #36c4ce;
}
.category-item .top {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-item .title {
  height: 50px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-item .title .text-inner {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-weight: bold;
}

.breadcrum {
  display: flex;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .breadcrum {
    margin-left: 10px;
  }
}
.breadcrum a,
.breadcrum li {
  list-style: none;
  display: block;
  font-size: 12px;
  color: #36c4ce !important;
  font-family: "Montserrat", sans-serif;
  padding: 0 15px;
  border-right: 1px solid #e6e6e6;
  cursor: pointer;
}
.breadcrum a:first-child,
.breadcrum li:first-child {
  padding-left: 0;
}
.breadcrum a:last-child,
.breadcrum li:last-child {
  border-right: none;
}
.breadcrum a.active,
.breadcrum li.active {
  color: #999999 !important;
  cursor: not-allowed;
}

.gray-text > * {
  color: #999999 !important;
}

.create-category-top {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.create-category-top .flex-right,
.create-category-top .flex-left {
  flex: 1 1;
}
.create-category-top .upload-title {
  font-size: 12px;
  color: #36c4ce;
  text-align: center;
  margin-top: 10px;
}
.create-category-top .flex-right {
  margin: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.category-view-main .flex-right,
.category-view-main .flex-left {
  min-width: 300px;
}
.category-view-main .flex-left td,
.category-view-main .flex-left th {
  padding: 10px 0;
  font-size: 13px;
}
.category-view-main .flex-left th {
  width: 100px;
  font-weight: 500;
}
.category-view-main .flex-right ul {
  margin-top: 20px;
}
.category-view-main .flex-right ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #b3b3b3;
  box-shadow: 0 5px 5px rgba(16, 16, 16, 0.02);
  transition: 0.3s ease;
  transform: scale(1);
}
.category-view-main .flex-right ul li:hover {
  transform: scale(1.05);
  color: #36c4ce;
}

.img-con {
  max-width: 40px;
  height: 40px;
  background-color: #e6e6e6;
  border-radius: 50%;
  overflow: hidden;
}
.img-con img {
  width: 100%;
  height: 100%;
}

.image-con-large {
  min-width: 100px !important;
  height: 100px !important;
}

.image-con-large2 {
  min-width: 150px !important;
  height: 150px !important;
}

.user-info-table th,
.user-info-table td {
  padding: 20px;
}

.support-table th {
  min-width: 100px;
}
.support-table th,
.support-table td {
  padding: 5px 0;
  box-sizing: border-box;
}

.table-check {
  display: flex;
  align-items: center;
}
.table-check label {
  display: block !important;
  bottom: 10px;
  left: 30px;
}

.ticket-info {
  position: fixed;
  right: 0;
  width: 500px;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(16, 16, 16, 0.07);
  padding-top: 80px;
  overflow: hidden;
}
.ticket-info .close {
  color: #36c4ce;
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
}

.ticket-info .close > span {
  cursor: pointer;
  display: flex;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #37c4ce;
  width: 20px;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
}

.ticket-info .close > span:hover {
  background: #37c4ce;
  color: #ffffff;
}

.ticket-info .header-main {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  box-shadow: 0 5px 10px rgba(16, 16, 16, 0.07);
}
.ticket-info .header-main p {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
}
.ticket-info .ticket-body {
  height: 100vh;
  padding: 20px;
  max-height: calc(100vh - 250px);
  overflow: auto;
  background-color: #f2f2f2;
  position: relative;
}
.ticket-info .ticket-body .replier,
.ticket-info .ticket-body .creator {
  max-width: 350px;
  margin-bottom: 20px;
}
.ticket-info .ticket-body .replier {
  margin-left: 110px;
}
.ticket-info .ticket-body .sender {
  border-top: 1px solid #f2f2f2;
  padding: 5px 10px;
  font-size: 13px;
}
.ticket-info .ticket-body .sender .user {
  color: #36c4ce;
}
.ticket-info .ticket-body .fileList {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #ffffff;
}
.ticket-info .ticket-body .fileList img {
  width: 50px;
  margin-right: 10px;
}
.ticket-info .ticket-body .fileList .fileListItem {
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
  box-sizing: border-box;
}
.ticket-info .ticket-body .fileList .fileListItem .close {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #da4e6a;
}
.ticket-info .footer-main {
  display: flex;
}
.ticket-info .footer-main button {
  width: 100px;
  height: 120px;
}
.ticket-info .footer-main .textzone {
  flex: 1 1;
  position: relative;
}
.ticket-info .footer-main .textzone .file-upload {
  position: absolute;
  z-index: 2;
  bottom: 5px;
  right: 5px;
  color: #36c4ce;
  cursor: pointer;
}

.category-container {
  margin-top: 20px;
}
.category-container .content-heading {
  font-weight: 500;
  font-size: 20px;
  color: #b3b3b3;
}
.category-container .add-content {
  font-weight: 300;
  font-size: 15px;
  color: #36c4ce;
  margin-left: 5px;
}
.category-container .service-item {
  flex: 1 1;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 250px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .service-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .service-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
}
.category-container .service-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .service-item .content-box svg {
  width: 60px !important;
}
.category-container .service-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .service-item .content-box h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-transform: capitalize;
  text-align: center;
}
.category-container .service-item ul {
  padding: 20px;
  color: gray;
}
.category-container .service-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .service-item ul li:last-child {
  border-bottom: none;
}
.category-container .product-item {
  flex: 1 1;
  margin-right: 2em;
  margin-bottom: 2em;
  background-color: white;
  box-shadow: 0 10px 20px rgba(16, 16, 16, 0.1);
  min-width: 300px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .category-container .product-item {
    margin-right: 0;
    max-width: 100%;
  }
}
.category-container .product-item .content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-direction: column;
  color: rgba(2, 48, 84, 0.6);
  transition: 0.3s ease;
  background-color: rgba(16, 16, 16, 0.02);
  position: relative;
}
.category-container .product-item .content-box button {
  position: absolute;
  top: 10px;
  right: 0;
  outline: none;
}
.category-container .product-item .content-box button ul {
  padding: 0 !important;
}
.category-container .product-item .content-box button ul span {
  font-size: 12px;
  color: black !important;
}
.category-container .product-item .content-box img {
  width: 100px;
  height: 100px;
}
.category-container .product-item .content-box svg {
  width: 60px !important;
}
.category-container .product-item .content-box svg path {
  fill: rgba(2, 48, 84, 0.6);
}
.category-container .product-item .content {
  padding: 20px;
}
.category-container .product-item .content p {
  font-size: 12px;
  color: black;
}
.category-container .product-item .content .content-head {
  margin-bottom: 10px;
}
.category-container .product-item .content .content-head span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 10px;
  display: block;
}
.category-container .product-item .content .status {
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  background-color: #36c4ce;
}
.category-container .product-item ul {
  padding: 20px;
  color: gray;
}
.category-container .product-item ul li {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-size: 14px;
}
.category-container .product-item ul li:last-child {
  border-bottom: none;
}

.layout-main {
  background-color: #f9feff;
  width: 100%;
  height: 100vh;
  position: relative;
}
.layout-main .side-bar {
  position: fixed;
  width: 200px;
  background-color: #023054;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
}
.layout-main .side-bar .brand {
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  color: white;
  font-weight: 500;
  background: #022d4e;
}
.layout-main .side-bar ul {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: calc(100vh - 100px);
  padding-bottom: 20px;
  /* width */
  /* Handle */
}
.layout-main .side-bar ul::-webkit-scrollbar {
  width: 3px;
}
.layout-main .side-bar ul::-webkit-scrollbar-thumb {
  background: #034c86;
  border-radius: 10px;
}
.layout-main .side-bar ul a {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: left;
  font-size: 13px;
  color: gray;
  padding: 30px 10px;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease;
}
.layout-main .side-bar ul a .side-icon {
  width: 50px;
}
.layout-main .side-bar ul a svg {
  margin-left: 5px;
}
.layout-main .side-bar ul a svg path {
  fill: gray;
}
.layout-main .side-bar ul a.active {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
}
.layout-main .side-bar ul a.active svg path {
  fill: #ffffff;
}
.layout-main .side-bar .down-indicator {
  text-align: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  color: white;
  background-color: inherit;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.layout-main .nav-bar {
  position: fixed;
  width: calc(100% - 200px);
  height: 80px;
  background-color: #ffffff;
  left: 200px;
  top: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
.layout-main .nav-bar .nav-right {
  height: 100%;
  display: flex;
}
.layout-main .nav-bar .nav-right li {
  width: unset;
  display: flex;
  cursor: pointer;
  list-style: none;
  padding: 0 20px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  color: #999999;
  border-bottom: 2px solid transparent;
}
.layout-main .nav-bar .nav-right li.active {
  color: #36c4ce;
  border-color: #36c4ce;
}
.layout-main .nav-bar .nav-left {
  height: 100%;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
}
.layout-main .nav-bar .nav-left select {
  width: unset;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  height: 30px;
  font-size: 16px;
  color: #6b6b6b;
  border: none;
  outline: none;
}
.layout-main .content-main {
  position: absolute;
  width: calc(100% - 200px);
  left: 200px;
  top: 80px;
  padding: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.layout-main .content-main.no-header {
  top: 0;
  height: 100vh;
}

.create-comp {
  min-width: 150px;
}

.text-muted {
  color: #999999;
}

/*# sourceMappingURL=default.css.map */

