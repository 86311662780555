@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
.dropdown-main {
  position: relative;
  z-index: 4;
}

.dropdown-main .dropdown-content {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-main ul {
  position: absolute;
  width: 100px;
  background: #ffffff;
  padding: 0;
  margin: 0;
  top: calc(100% + 10px);
  right: 0;
  -webkit-box-shadow: 0 5px 10px rgba(16, 16, 16, 0.1);
          box-shadow: 0 5px 10px rgba(16, 16, 16, 0.1);
  max-height: 400px;
  overflow-y: auto;
  -webkit-transform-origin: right top;
          transform-origin: right top;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  /* width */
  /* Handle */
}

.dropdown-main ul::-webkit-scrollbar {
  width: 3px;
}

.dropdown-main ul::-webkit-scrollbar-thumb {
  background: #d8d6d6;
  border-radius: 10px;
}

.dropdown-main ul li {
  list-style: none;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2E2E2E;
  font-size: 14px;
  padding: 12px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}

.dropdown-main.open ul {
  opacity: 1;
  visibility: visible;
}
/*# sourceMappingURL=Dropdown.css.map */