@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
.file-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.file-upload-main .file-upload-items {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  height: 150px;
  width: 150px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transform-origin: left top;
  transform: scale(0);
  animation: animateFileEntry 0.3s ease-in-out forwards;
  overflow: hidden;
  position: relative;
}
.file-upload-main .file-upload-items .loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /**
  * $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
  */
}
.file-upload-main .file-upload-items .loading .progress-info {
  position: relative;
  width: 100%;
}
.file-upload-main .file-upload-items .loading .progress-info::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  content: attr(data-info);
}
.file-upload-main .file-upload-items .loading .progress-circle {
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  position: relative;
  width: 100px;
}
.file-upload-main .file-upload-items .loading .progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-progress) "%";
  display: flex;
  font-size: 12px;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
}
.file-upload-main .file-upload-items .loading .progress-circle:after {
  background-color: #0083ff;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(90deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(93.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(97.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(100.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(104.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(108deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(111.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(115.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(118.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(122.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(126deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(129.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(133.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(136.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(140.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(144deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(147.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(151.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(154.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(158.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(162deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(165.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(169.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(172.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(176.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(180deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(183.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(187.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(190.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(194.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(198deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(201.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(205.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(208.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(212.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(216deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(219.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(223.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(226.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(230.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(234deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(237.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(241.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(244.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(248.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(252deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(255.6deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(259.2deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(262.8deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(266.4deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(-90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(-86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(-82.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(-79.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(-75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(-72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(-68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(-64.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(-61.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(-57.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(-54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(-50.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(-46.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(-43.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(-39.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(-36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(-32.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(-28.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(-25.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(-21.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(-18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(-14.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(-10.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(-7.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(-3.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(0deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(3.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(7.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(10.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(14.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(21.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(25.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(28.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(32.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(39.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(43.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(46.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(50.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(57.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(61.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(64.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(79.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(82.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading .progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ffffff 50%, #ffffff);
}
.file-upload-main .file-upload-items .loading.close {
  z-index: -1;
}
.file-upload-main .file-upload-items .file-upload-controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.file-upload-main .file-upload-items .file-upload-controls button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  color: white;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s ease;
}
.file-upload-main .file-upload-items .file-upload-controls button:hover {
  background: rgba(0, 0, 0, 0.5);
}
.file-upload-main .file-upload-items img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  border: none;
}
.file-upload-main .file-upload-items:hover .file-upload-controls {
  z-index: 3;
  visibility: visible;
}
.file-upload-main.single .file-upload-items {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.file-upload-main .file-upload-items.error {
  border-color: #da4e6a;
}
.file-upload-main .file-upload-items.error .file-upload-controls .preview-button {
  display: none;
}
.file-upload-main .file-upload-button {
  height: 150px;
  width: 150px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}
.file-upload-main .file-upload-items.close {
  transform-origin: left top;
  transform: scale(1);
  animation: animateFileExit 0.3s ease-in-out forwards;
}

@keyframes animateFileEntry {
  to {
    transform: scale(1);
  }
}
@keyframes animateFileExit {
  to {
    transform: scale(0);
  }
}

/*# sourceMappingURL=FileUpload.css.map */
